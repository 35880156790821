import { Box, Button } from '@trmediaab/zebra';
import Link from 'next/link';

import ActiveRounds from '~/components/ActiveRounds';
import AboutSpelvarde from '~/components/AsideComponents/AboutSpelvarde';
import FromSchool, {
  schoolTeaserListQueryArgs,
} from '~/components/AsideComponents/FromSchool';
import MoreFromSpelvarde from '~/components/AsideComponents/MoreFromSpelvarde';
import MoreFromTRMedia from '~/components/AsideComponents/MoreFromTRMedia';
import OveroddsBestgameWidget from '~/components/AsideComponents/OveroddsBestGameWidget';
import SharedBets from '~/components/AsideComponents/SharedBets';
import PageLayout from '~/components/PageLayout';
import SiteLayout from '~/components/SiteLayout';
import Ticker from '~/components/Ticker';
import { wrapper } from '~/main/store';
import { traisApi } from '~/services/trais';
import {
  getAuthorList,
  getSchoolTeaserList,
  getTickerTeaserList,
} from '~/services/trais/content';
import { getActiveRounds } from '~/services/trais/round';

const Home = () => (
  <>
    <Ticker />
    <PageLayout
      main={<Box bg="greys.1">Primär omgång</Box>}
      rounds={
        <>
          <ActiveRounds />
          <Box mt="3" display={['none', null, 'block']}>
            <Link href="/tips" passHref>
              <Button bc="secondary" width="100%">
                Visa alla aktuella tips
              </Button>
            </Link>
          </Box>
        </>
      }
      aside={
        <>
          <OveroddsBestgameWidget />
          <MoreFromSpelvarde />
          <SharedBets />
          <FromSchool />
          <AboutSpelvarde />
          <MoreFromTRMedia />
        </>
      }
    />
  </>
);

Home.getLayout = (page: React.ReactElement) => <SiteLayout>{page}</SiteLayout>;

export const getServerSideProps = wrapper.getServerSideProps(
  store => async () => {
    store.dispatch(getActiveRounds.initiate());
    store.dispatch(getAuthorList.initiate());
    store.dispatch(getSchoolTeaserList.initiate(schoolTeaserListQueryArgs));
    store.dispatch(getTickerTeaserList.initiate());

    await Promise.all(store.dispatch(traisApi.util.getRunningQueriesThunk()));

    return {
      props: {},
    };
  },
);

export default Home;
