import { Anchor, Box, Heading, List, Rhythm, Text } from '@trmediaab/zebra';
import Link from 'next/link';

import ClampedText from '~/components/ClampedText';
import CloudinaryImage from '~/components/CloudinaryImage';
import QueryResult from '~/components/QueryResult';
import { useGetSchoolTeaserListQuery } from '~/services/trais/content';
import { getArticleUrl, getTeaserMedia } from '~/utils/articleUtils';

import AsideHeader from '../AsideHeader';
import AsideLink from '../AsideLink';

export const schoolTeaserListQueryArgs = {
  limit: 3,
  featured: true,
};

const FromSchool = () => {
  const schoolTeaserListQuery = useGetSchoolTeaserListQuery(
    schoolTeaserListQueryArgs,
  );

  return (
    <Box>
      <AsideHeader title="Från spelskolan" />
      <Rhythm between="5">
        <QueryResult
          queryResult={schoolTeaserListQuery}
          render={teaserList => (
            <List gap="3,5" divided>
              {teaserList.results.map(teaser => (
                <List.listItem key={teaser.id} sx={{ cursor: 'pointer' }}>
                  <Link href={getArticleUrl(teaser)} passHref>
                    <Anchor
                      display="flex"
                      key={teaser.id}
                      variant="matchText"
                      textDecoration="none"
                    >
                      <Box mr="3,5" flex="1">
                        <Heading.h3 variant="fat" mb="1">
                          {teaser.teaser_headline}
                        </Heading.h3>
                        <ClampedText lines={4}>
                          <Text fontSize="2">{teaser.preamble}</Text>
                        </ClampedText>
                      </Box>
                      <Box>
                        <CloudinaryImage
                          image={getTeaserMedia(teaser)}
                          ratio={[16, 10]}
                          width={150}
                          wrapperProps={{
                            width: [90, null, null, null, 150],
                            flexShrink: 0,
                            sx: {
                              borderRadius: 2,
                              overflow: 'hidden',
                            },
                          }}
                        />
                      </Box>
                    </Anchor>
                  </Link>
                </List.listItem>
              ))}
            </List>
          )}
        />
        <AsideLink href="/spelskola" text="Gå till spelskolan" />
      </Rhythm>
    </Box>
  );
};

export default FromSchool;
