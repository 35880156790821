import { Heading } from '@trmediaab/zebra';

interface AsideHeaderProps {
  title: string;
}

const AsideHeader = ({ title }: AsideHeaderProps) => (
  <>
    <Heading.h2
      variant="fat"
      mb="3,5"
      color="darkBlue"
      sx={{
        borderTopWidth: '3px',
        borderTopStyle: 'solid',
        borderColor: 'vividRed',
      }}
      width="fit-content"
      pt="2"
    >
      {title}
    </Heading.h2>
  </>
);

export default AsideHeader;
