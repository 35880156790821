import {
  AnyArticle,
  AnyTeaser,
  ArchiveImage,
  AuthorImage,
  PodTvArticle,
  SchoolArticle,
} from '~/types/models/content';

function toArchiveImage(
  id: string,
  focusPoint: Nullable<string> = null,
  caption: Nullable<string> = null,
  photographer: Nullable<string> = null,
): ArchiveImage {
  return {
    cdn_id: id,
    focus_point: focusPoint,
    caption,
    photographer,
  };
}

export const getImageAlt = (image: ArchiveImage | AuthorImage) =>
  image.caption ?? Boolean(image.photographer)
    ? `Foto: ${image.photographer}`
    : '';

export const getTeaserMedia = (article: AnyTeaser) => {
  if (!article) {
    return null;
  }

  if (article.teaser_image != null) {
    return article.teaser_image;
  }
  if (article.video?.['image_id']) {
    return toArchiveImage(article.video.image_id, null, article.video.title);
  }
  if (article.archive_images.length > 0) {
    return article.archive_images[0];
  }

  return null;
};

export const getAuthorImage = (article: PodTvArticle | SchoolArticle) => {
  const author = article.authors[0];
  if (author?.image?.['image_id']) {
    const { image, firstname, lastname } = author;
    return {
      id: image.image_id,
      alt: `${firstname} ${lastname}`,
    };
  }
};

export function getArticleUrl(article: AnyArticle | AnyTeaser) {
  let prefix = '';

  if (article.type === 'betting_school') {
    const categoryPaths = {
      beginner: 'nyborjare',
      medium: 'medium',
      expert: 'expert',
    };
    prefix = `/spelskola/${categoryPaths[article.category]}`;
  } else if (article.type === 'podtv') {
    prefix = '/podd-tv';
  }

  return `${prefix}/${article.slug}`;
}
