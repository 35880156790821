import Script from 'next/script';

const OveroddsBestgameWidget = () => (
  <>
    <Script
      id="overodds-embed"
      strategy="lazyOnload"
      src="https://www.overodds.se/embed.js"
    />
    <div
      id="overodds-widget"
      dangerouslySetInnerHTML={{
        __html: `<overodds-widget url="https://www.overodds.se/embed/bestgame"/>`,
      }}
    />
  </>
);

export default OveroddsBestgameWidget;
