import { Anchor, Box, List, Text } from '@trmediaab/zebra';

import AsideHeader from '../AsideHeader';

const items = [
  {
    title: 'Travfakta',
    text: 'Bacon ipsum dolor amet strip steak rump meatball pancetta sausage, alcatra frankfurte tongue pigalle  dolor amet strip steak rump meatball ',
    href: 'https://www.travfakta.se',
  },
  {
    title: 'Jokersystemet',
    text: 'Bacon ipsum dolor amet strip steak rump meatball pancetta sausage, alcatra frankfurte tongue pigalle  dolor amet strip steak rump meatball ',
    href: 'https://www.jokersystemet.se',
  },
];

const MoreFromTRMedia = () => (
  <Box>
    <AsideHeader title="Mer från TR Media" />
    <List gap="3,5" divided>
      {items.map(({ title, text, href }) => (
        <List.listItem key={title}>
          <Anchor
            fontWeight="bold"
            href={href}
            rel="noopener noreferrer"
            target="_blank"
          >
            {title}
          </Anchor>
          <Text>{text}</Text>
        </List.listItem>
      ))}
    </List>
  </Box>
);

export default MoreFromTRMedia;
