import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as FlameIcon } from './flame.svg';

const Flame = createLocalStyledIcon(FlameIcon);

Flame.defaultProps = {
  width: 16,
  height: 'auto',
};

export default Flame;
