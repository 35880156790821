import { Button } from '@trmediaab/zebra';
import Link from 'next/link';

interface AsideLinkProps {
  href: string;
  text: string;
  external?: boolean;
}

const AsideLink = ({ href, text, external }: AsideLinkProps) => (
  <Link href={href} passHref>
    <Button
      rel={external ? 'noopener noreferrer' : ''}
      target={external ? '_blank' : ''}
      width="100%"
      bc="tertiary"
    >
      {text}
    </Button>
  </Link>
);

export default AsideLink;
