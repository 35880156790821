import { createLocalStyledIcon } from '@trmediaab/zebra-icons';
import styled from 'styled-components';

import { default as ArrowIcon } from './arrow.svg';

type PointTo =
  | 'up'
  | 'upLeft'
  | 'upRight'
  | 'right'
  | 'down'
  | 'downLeft'
  | 'downRight'
  | 'left';

interface ArrowProps {
  pointTo: PointTo;
  transition: boolean;
}

function getRotation(pointTo: PointTo) {
  switch (pointTo) {
    case 'up':
      return 'rotate(90deg)';
    case 'upLeft':
      return 'rotate(45deg)';
    case 'upRight':
      return 'rotate(135deg)';
    case 'down':
      return 'rotate(-90deg)';
    case 'downLeft':
      return 'rotate(-45deg)';
    case 'downRight':
      return 'rotate(-135deg)';
    case 'right':
      return 'rotate(180deg)';
    default:
      return;
  }
}

const Arrow = styled(createLocalStyledIcon(ArrowIcon))<ArrowProps>`
  transform: ${(props: ArrowProps) => getRotation(props.pointTo)};
  transition: transform ${(props: ArrowProps) => (props.transition ? 0.2 : 0)}s
    ease-in-out;
`;

Arrow.defaultProps = {
  width: 10,
  height: 'auto',
  pointTo: 'left',
  transition: true,
};

export default Arrow;
