import { Anchor, BorderBox, Box, Text } from '@trmediaab/zebra';
import { Time } from '@trmediaab/zebra-components';
import { CheckCircle } from '@trmediaab/zebra-icons';
import Link from 'next/link';
import { useTheme } from 'styled-components';

import Arrow from '~/icons/Arrow';
import { ActiveRound } from '~/types/models/round/Round';
import { createRoundUrl } from '~/utils/url';

interface RoundListButtonProps {
  round: ActiveRound;
  responsive: boolean;
}

function makeStyleHelper(responsive: boolean) {
  return function styleHelper<TMobile = null | unknown, TDesktop = unknown>(
    mobile: TMobile,
    desktop: TDesktop,
  ): [TMobile, null, TDesktop] | TDesktop {
    return responsive ? [mobile, null, desktop] : desktop;
  };
}

const RoundListButton = ({ round, responsive }: RoundListButtonProps) => {
  const styleHelper = makeStyleHelper(responsive);
  const theme = useTheme();

  return (
    <Link href={createRoundUrl(round.slug)} passHref>
      <Anchor variant="matchText" fontWeight="medium">
        <BorderBox
          display="flex"
          flexDirection={styleHelper('column', 'row')}
          alignItems="center"
          bg={styleHelper(null, 'white')}
          borderWidth={styleHelper(0, 1)}
          borderStyle="solid"
          borderColor="border"
          borderRadius={styleHelper(null, 1)}
          overflow="hidden"
        >
          <Text
            width="buttonHeight"
            lineHeight="buttonHeight"
            bg={`atg.${round.bet_type}.primary`}
            fontFamily="secondary"
            fontSize="3"
            fontWeight="bold"
            color="white"
            textAlign="center"
            sx={{
              background: `linear-gradient(0deg, ${
                theme.colors.atg[round.bet_type].gradient[0]
              } 0%, ${theme.colors.atg[round.bet_type].gradient[1]} 100%)`,
              borderRadius: styleHelper(1, 0),
            }}
          >
            {round.bet_type}
          </Text>
          <Text
            ml={styleHelper(null, 3)}
            flex="1"
            lineHeight={['medium', null, 'tight']}
          >
            {responsive ? (
              <>
                <Text.inline display={['none', null, 'inline']}>
                  {round.track_name}{' '}
                  <Time date={round.round_date} format="d MMMM" />
                </Text.inline>
                <Text.inline display={['inline', null, 'none']} fontSize="0">
                  <Time
                    date={round.round_date}
                    format="EEE d/M"
                    css={`
                      display: inline-block; /* must be block level for first-letter */
                      ::first-letter {
                        text-transform: uppercase;
                      }
                    `}
                  />
                </Text.inline>
              </>
            ) : (
              <>
                {round.track_name}{' '}
                <Time date={round.round_date} format="d MMMM" />{' '}
              </>
            )}
          </Text>
          <Box
            display={styleHelper('none', 'block')}
            flexShrink="0"
            ml="2"
            mr="2,5"
          >
            {round.analysis != null ? (
              <CheckCircle width="20px" color="green" />
            ) : (
              <Arrow width="13px" color="greys.1" pointTo="right" />
            )}
          </Box>
        </BorderBox>
      </Anchor>
    </Link>
  );
};

export default RoundListButton;
