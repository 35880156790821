import { Box, List, Rhythm, Text } from '@trmediaab/zebra';

import AuthorImage from '~/components/AuthorImage';
import QueryResult from '~/components/QueryResult';
import { useGetAuthorListQuery } from '~/services/trais/content';

import AsideHeader from '../AsideHeader';
import AsideLink from '../AsideLink';

const AboutSpelvarde = () => {
  const authorsListQuery = useGetAuthorListQuery();

  return (
    <Box>
      <AsideHeader title="Om Spelvärde" />
      <Rhythm between="5">
        <Text>
          Spelvärde.se grundades 2014 med ambitionen att erbjuda världens mest
          genomarbetade och bästa travtips. Våra starka resultat medförde att vi
          snabbt blev Sveriges ledande tipstjänst för spel på trav - en position
          vi behållit sedan dess.
        </Text>
        <QueryResult
          queryResult={authorsListQuery}
          render={authorsList => (
            <List gap="2">
              {[...authorsList.results]
                .sort((a, b) => a.firstname.localeCompare(b.firstname, 'sv'))
                .map(author => (
                  <List.listItem
                    key={author.id}
                    display="flex"
                    alignItems="center"
                  >
                    <AuthorImage
                      image={author.image}
                      variant="small"
                      wrapperProps={{
                        marginRight: 3,
                      }}
                    />
                    <Box>
                      <Text.p fontWeight="bold">
                        {author.firstname} {author.lastname}
                      </Text.p>
                      <Text.p color="greys.3">{author.title}</Text.p>
                    </Box>
                  </List.listItem>
                ))}
            </List>
          )}
        />
        <AsideLink href="/om-spelvarde" text="Läs mer om Spelvärde" />
      </Rhythm>
    </Box>
  );
};

export default AboutSpelvarde;
