import { Box, Rhythm, Text } from '@trmediaab/zebra';

import AsideHeader from '../AsideHeader';
import AsideLink from '../AsideLink';

const SharedBets = () => (
  <Box>
    <AsideHeader title="Andelar på KöpAndel.se" />
    <Rhythm between="5">
      <Text>
        Bacon ipsum dolor amet strip steak rump meatball pancetta sausage,
        alcatra frankfurte tongue pigalle lorem acon ipsum dolor amet strip
        steak rump
      </Text>
      <AsideLink
        href="https://kopandel.se/"
        text="Gå till KöpAndel.se"
        external
      />
    </Rhythm>
  </Box>
);

export default SharedBets;
