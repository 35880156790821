import { Box, Button, Rhythm, Text } from '@trmediaab/zebra';
import { createLocalStyledIcon } from '@trmediaab/zebra-icons';
import Link from 'next/link';

import Calendar from '~/icons/Calendar';
import School from '~/icons/School';
import SharedBets from '~/icons/SharedBets';
import Track from '~/icons/Track';

import AsideHeader from '../AsideHeader';

const asideItemsMap: {
  title: string;
  text: string;
  href: string;
  key: string;
}[] = [
  {
    title: 'Andelssspel',
    text: 'Spela tillsammans med Spelvärde',
    href: '/andelsspel',
    key: 'sharedBets',
  },
  {
    title: 'Spelskola',
    text: 'Kunskap för både nybörjare och experter',
    href: '/spelskola',
    key: 'school',
  },
  {
    title: 'Banfakta',
    text: 'Bra att veta för att ta rätt spelbeslut',
    href: '/banfakta',
    key: 'track',
  },
  {
    title: 'V75-kalender',
    text: 'De viktigaste travdagarna i Sverige',
    href: '/v75-kalender',
    key: 'v75Calendar',
  },
];

const iconProps = {
  color: 'vividRed',
  width: '25px',
};

const iconMap: Record<string, ReturnType<typeof createLocalStyledIcon>> = {
  sharedBets: <SharedBets {...iconProps} />,
  v75Calendar: <Calendar {...iconProps} />,
  school: <School {...iconProps} />,
  track: <Track {...iconProps} />,
};

const MoreFromSpelvarde = () => (
  <Box>
    <AsideHeader title="Mer från Spelvärde" />
    <Rhythm between="2">
      {asideItemsMap.map(({ title, text, href, key }) => (
        <Link key={title} href={href} passHref>
          <Button
            colorScheme="tertiary"
            color="text.base"
            fontWeight="regular"
            shape="square"
            size="none"
            width="100%"
            justifyContent="start"
            px={[3, null, null, null, '3,5']}
            py="2,5"
          >
            <Box>{iconMap[key]}</Box>
            <Box ml="3">
              <Text fontWeight="bold" mb="1">
                {title}
              </Text>
              <Text fontSize={[1, null, 2]}>{text}</Text>
            </Box>
          </Button>
        </Link>
      ))}
    </Rhythm>
  </Box>
);

export default MoreFromSpelvarde;
