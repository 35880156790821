import { BorderBox, Box, Container, Heading, List } from '@trmediaab/zebra';
import { useEffect, useState } from 'react';

import { default as FlameIcon } from '~/icons/Flame';
import { useGetTickerTeaserListQuery } from '~/services/trais/content';
import { TickerArticle } from '~/types/models/content';

import QueryResult from '../QueryResult';

interface RenderTickerProps {
  results: TickerArticle[];
}

const INTERVAL = 6 * 1000;

const RenderTicker = ({ results }: RenderTickerProps) => {
  const [currentItem, setCurrentItem] = useState<number>(0);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCurrentItem(current =>
        current === results.length - 1 ? 0 : current + 1,
      );
    }, INTERVAL);
    return () => {
      window.clearInterval(intervalId);
    };
  }, [results.length]);

  return (
    <Box
      py="3"
      sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)' }}
      overflow="hidden"
    >
      <Container display="flex" alignItems="center">
        <BorderBox
          display="flex"
          borderRightColor="greys.1"
          borderRightStyle="solid"
          borderRightWidth={['0', null, null, null, '1px']}
          minWidth="auto"
        >
          <FlameIcon />
          <Heading.h4
            ml="2"
            mr="2,5"
            display={['none', null, null, null, 'block']}
          >
            Aktuellt
          </Heading.h4>
        </BorderBox>
        <List width="100%" sx={{ position: 'relative' }}>
          {results.map((result, index) => (
            <List.listItem
              key={result.id}
              ml="2,5"
              lineHeight="1.3"
              sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                transition:
                  'opacity 1s ease-in-out var(--opacity-delay, 0ms),transform 1s ease-in-out',
                opacity: 0,
              }}
              css={
                index === currentItem && `--opacity-delay: 500ms; opacity: 1;`
              }
            >
              {result.headline}
            </List.listItem>
          ))}
        </List>
      </Container>
    </Box>
  );
};

const Ticker = () => {
  const queryResult = useGetTickerTeaserListQuery();

  return (
    <QueryResult
      queryResult={queryResult}
      render={data => <RenderTicker results={data.results} />}
      wrap={(children, resultType) => (resultType === 'data' ? children : null)}
    />
  );
};

export default Ticker;
