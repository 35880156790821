import { List } from '@trmediaab/zebra';

import { useGetActiveRoundsQuery } from '~/services/trais/round';

import Bleed from '../Bleed';
import QueryResult from '../QueryResult';
import RoundListButton from '../RoundListButton';

const ActiveRounds = () => {
  const queryResult = useGetActiveRoundsQuery();

  return (
    <QueryResult
      queryResult={queryResult}
      render={data => (
        <Bleed>
          <List
            display="grid"
            py={[3, null, 0]}
            px={['gutters.base', null, 0]}
            sx={{
              boxShadow: ['0px 4px 10px rgba(0, 0, 0, 0.15)', null, 'none'],
              gap: ['2,5', null, '2'],
              gridTemplateColumns: [
                `repeat(${data.length}, min-content)`,
                null,
                '1fr',
              ],
              overflowX: 'auto',
            }}
            css={`
              scrollbar-width: none;
              -ms-overflow-style: none;
              -webkit-overflow-scrolling: touch;
              ::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            {data.map(round => (
              <List.listItem key={round.id}>
                <RoundListButton round={round} responsive />
              </List.listItem>
            ))}
          </List>
        </Bleed>
      )}
    />
  );
};

export default ActiveRounds;
