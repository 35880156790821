import { AuthorImage as AuthorImageType } from '~/types/models/content';

import CloudinaryImage, { WrapperProps } from '../CloudinaryImage';

interface AuthorImageProps {
  image: Nullable<AuthorImageType>;
  variant?: 'small' | 'large';
  wrapperProps?: WrapperProps;
}

const AuthorImage = ({
  image,
  variant = 'large',
  wrapperProps,
}: AuthorImageProps) => (
  <CloudinaryImage
    image={image}
    width={variant === 'small' ? 50 : 80}
    layout="intrinsic"
    ratio={[1, 1]}
    transforms={['g_face', 'c_thumb', 'z_0.85', 'r_max']}
    wrapperProps={{
      width: variant === 'small' ? 50 : [60, null, 80],
      ...wrapperProps,
    }}
  />
);

export default AuthorImage;
