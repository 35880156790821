import type {
  AnyArticle,
  Author,
  PodTvTeaser,
  QuestionArticle,
  SchoolArticle,
  SchoolTeaser,
  TickerTeaser,
} from '~/types/models/content';
import { StartInterview } from '~/types/models/content/StartInterview';
import { ListResponse, PagedList } from '~/types/models/utils';

import { argsToParams } from '../utils';
import { traisApi } from '.';

type ContentArgs = {
  slug: string;
};

export type TeaserListQueryArgs = {
  limit: number;
  offset?: number;
  exclude_slug?: string;
  featured?: boolean;
};

type RoundOrLegInterviewsQueryArgs = {
  leg?: number | undefined;
  round_slug: string;
  limit: number;
};

type HorseInterviewsQueryArgs = {
  horse: number;
  limit: number;
};

type ArchivedInterviewsQueryArgs = HorseInterviewsQueryArgs & {
  offset: number;
  start_time_lt: string;
};

export type PostQuestionMutationArgs = {
  round_id: string;
  receiver: string;
  question: string;
  alias: string;
  agree: 'on';
};

// Paged lists, ie where the user can navigate between pages,
// need to have limit and offset in the state
function transformToPagedList<
  T,
  TArgs extends { limit: number; offset?: number },
>(response: ListResponse<T>, meta: unknown, args: TArgs) {
  return {
    ...response,
    limit: args.limit,
    offset: args.offset || 0,
  };
}

const contentApi = traisApi.injectEndpoints({
  endpoints: builder => ({
    getContent: builder.query<AnyArticle, ContentArgs>({
      query: ({ slug }) => `content/${slug}.json`,
    }),
    getPodTvTeaserList: builder.query<
      PagedList<PodTvTeaser>,
      TeaserListQueryArgs
    >({
      query: filters =>
        `content.json?${argsToParams({ ...filters, type: 'podtv' })}`,
      transformResponse: transformToPagedList,
    }),
    getTickerTeaserList: builder.query<ListResponse<TickerTeaser>, void>({
      query: () => `content.json?${argsToParams({ type: 'campaign' })}`,
    }),
    getSchoolTeaserList: builder.query<
      PagedList<SchoolTeaser>,
      TeaserListQueryArgs & { category?: SchoolArticle['category'] }
    >({
      query: filters =>
        `content.json?${argsToParams({
          ...filters,
          type: 'betting_school',
        })}`,
      transformResponse: transformToPagedList,
    }),
    getAuthorList: builder.query<ListResponse<Author>, void>({
      query: () => `content/authors.json`,
    }),
    getInterviews: builder.query<
      ListResponse<StartInterview>,
      | ArchivedInterviewsQueryArgs
      | HorseInterviewsQueryArgs
      | RoundOrLegInterviewsQueryArgs
    >({
      query: filters =>
        `content/interviews.json?${argsToParams({
          ...filters,
        })}`,
    }),
    getArchivedInterviews: builder.query<
      ListResponse<StartInterview>,
      ArchivedInterviewsQueryArgs
    >({
      query: filters =>
        `content/interviews.json?${argsToParams({
          ...filters,
        })}`,
      serializeQueryArgs: ({ queryArgs }) => ({
        horse: queryArgs.horse,
      }),
      merge: (currentCache, res) => ({
        ...res,
        results: [...currentCache.results, ...res.results],
      }),
      forceRefetch({ currentArg, previousArg }) {
        // Offset resets to 0 whenever the page-route changes
        // Check if currOffset is greater than prevOffset to force refetch
        const currOffset = currentArg?.offset || 0;
        const prevOffset = previousArg?.offset || 0;

        return currOffset !== prevOffset && currOffset > prevOffset;
      },
    }),
    getQuestions: builder.query<QuestionArticle[], { roundSlug: string }>({
      query: ({ roundSlug }) =>
        `content/live.json?${argsToParams({
          round_slug: roundSlug,
        })}`,
    }),
    postQuestion: builder.mutation<void, PostQuestionMutationArgs>({
      query: payload => ({
        url: `content/live/question.json`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/json;',
        },
      }),
    }),
  }),
});

export const {
  useGetContentQuery,
  useGetPodTvTeaserListQuery,
  useGetSchoolTeaserListQuery,
  useGetTickerTeaserListQuery,
  useGetAuthorListQuery,
  useGetInterviewsQuery,
  useGetArchivedInterviewsQuery,
  useGetQuestionsQuery,
  usePostQuestionMutation,
  endpoints: {
    getContent,
    getPodTvTeaserList,
    getSchoolTeaserList,
    getTickerTeaserList,
    getAuthorList,
    getInterviews,
    getQuestions,
  },
} = contentApi;
